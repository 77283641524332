import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaintRecipeService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/paint/recipe`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/paint/recipe/`+id);
    } 

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/paint/recipe/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/paint/recipe`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/paint/recipe/`+id);
    }


}
