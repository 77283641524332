import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/delivery`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/delivery/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/delivery/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/delivery`,formData);
	  }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/delivery/`+id);
    }

    start(id, delivery){
      return this.http.post(`${environment.baseUrl}/delivery/`+id+`/start`, delivery);
    }

    secured(id, delivery){
      return this.http.post(`${environment.baseUrl}/delivery/`+id+`/secured`, delivery);
    }

    arrived(id, delivery){
      return this.http.post(`${environment.baseUrl}/delivery/`+id+`/arrived`, delivery);
    }

    complete(id, delivery){
      return this.http.post(`${environment.baseUrl}/delivery/`+id+`/complete`, delivery);
    }

    partial(id, items){
      return this.http.post(`${environment.baseUrl}/delivery/`+id+`/partial`, items);
    }

}
