import { HostListener } from "@angular/core";
import { DepartmentModel} from "./department.model";
import { SupplierModel } from "./supplier.model";

export class StockModel{

    id:number;
    reference:string;
    description:string;
    stock_amount:number;
    stock_type:string;
    barcode:string;
    avg_purchase_price:number;
    sale_price:number;
    supplier:SupplierModel;
    department: DepartmentModel;
    min_stock_limit:number;
    stock_reorder_limit:number;
    tax_amount:number;
    image_path:string;
	image:string;
	prices: any;
	lots: any;
	locations: any;
	is_sale_price_percentage:boolean;
	supplier_reference: string;
	qty: number = 0;
    
    constructor(input?){
        input = input || {};
        this.id = input.id;
        this.reference = input.reference;
        this.description = input.description;
        this.stock_amount = input.stock_amount;
        this.stock_type = input.stock_type;
        this.barcode = input.barcode;
        this.avg_purchase_price = input.avg_purchase_price;
        this.sale_price = input.sale_price;
        this.min_stock_limit = input.min_stock_limit;
        this.stock_reorder_limit = input.stock_reorder_limit;
        this.tax_amount = input.tax_amount;
        this.supplier = input.supplier;
        this.department = input.department;
        this.image = input.image;
        this.image_path = input.image_path;
        this.prices = input.prices;
        this.lots = input.lots;
        this.locations = input.locations;
        this.is_sale_price_percentage = input.is_sale_price_percentage;
        this.supplier_reference = input.supplier_reference;
	}

	
	salePrice(quantity, account?)
	{
		if(account != undefined)
		{
			return this.sale_price - (this.sale_price * (account.discount_percentage/100));
		}

		let selectedPrice = this.sale_price;

		this.prices.forEach((price) => {
			if(price.min_quantity <= quantity)
			{
				selectedPrice = price.price;
			}
			else
			{
				return;
			}
		});

		return selectedPrice;
	}

}