export class BankAccountModel {

    id: number;
    type: string;
    title: string;
    account_number: string;
    status: string;
    running_balance: number;

    constructor(input?){
        input = input || {};
        this.id = input.id || '';
        this.title = input.title || '';
        this.account_number = input.account_number || '';
        this.type = input.type || '';
        this.status = input.status || '';
        this.running_balance = input.running_balance || 0;
    }

}